import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";
import navLogo from '../../../Assets/image/Practcode logo.png'



const Navbar = () => {
	return (
		<div className="Mycourse-navbar">
			{/* <div className="avtar-container"></div> */}
			{/* <img className="mycourse-navlogo" src={navLogo} alt="" /> */}
			<div className="practcode-ai">Practcode Ai</div>
			<div className="mycourse-navbar-options">
				<NavLink to={"/"}>Explore More</NavLink>
				<NavLink to={"/"}>Contests</NavLink>
				<NavLink to={"/"}>Leaderbord</NavLink>
				<NavLink to={"/"}>Play Ground</NavLink>
			</div>
			<div>
		
				<div className="mycourse-navbar-notifier-box">
					<svg
						viewBox="0 0 24 24"
						fill="none"
						height="24"
						width="24"
						xmlns="http://www.w3.org/2000/svg"
						aria-hidden="true"
						className="w-6 h-6 text-gray-800 dark:text-white"
					>
						<path
							d="M12 5.365V3m0 2.365a5.338 5.338 0 0 1 5.133 5.368v1.8c0 2.386 1.867 2.982 1.867 4.175 0 .593 0 1.292-.538 1.292H5.538C5 18 5 17.301 5 16.708c0-1.193 1.867-1.789 1.867-4.175v-1.8A5.338 5.338 0 0 1 12 5.365ZM8.733 18c.094.852.306 1.54.944 2.112a3.48 3.48 0 0 0 4.646 0c.638-.572 1.236-1.26 1.33-2.112h-6.92Z"
							strokeWidth="2"
							strokeLinejoin="round"
							strokeLinecap="round"
							stroke="currentColor"
						></path>
					</svg>
					<div className="point"></div>
				</div>

				<button id="btn-message" className="button-message">
					<div className="content-avatar">
						<div className="status-user"></div>
						<div className="avatar">
							{/* <svg className="user-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12,12.5c-3.04,0-5.5,1.73-5.5,3.5s2.46,3.5,5.5,3.5,5.5-1.73,5.5-3.5-2.46-3.5-5.5-3.5Zm0-.5c1.66,0,3-1.34,3-3s-1.34-3-3-3-3,1.34-3,3,1.34,3,3,3Z"></path></svg> */}
							<img src="./fav2.png" className="user-img" alt="" />
						</div>
					</div>
					<div className="notice-content">
						<div className="username">USER-NAME</div>
						<div className="lable-message">User-name</div>
						<div className="user-id">@USERNAME</div>
					</div>
				</button>
			</div>
		</div>
	);
};

export default Navbar;
