import React from 'react'

const DisplayCode=({ rawCode })=> {
    

    // Format the raw data
    const formattedCode = rawCode
      .replace(/\\n/g, "\n") // Replace newline characters
      .replace(/\\t/g, "    ") // Replace tab characters
      .replace(/\\/g, ""); // Remove escape backslashes
  
    return (
      <div style={styles.container}>
        <pre style={styles.pre}>
          <code>{formattedCode}</code>
        </pre>
      </div>
    );
  };
  
  const styles = {
    container: {
      backgroundColor: "#f4f4f4",
      border: "1px solid #ddd",
      padding: "10px",
      overflowX: "auto",
      whiteSpace: "pre-wrap",
      fontFamily: "'Courier New', Courier, monospace",
      color: "#333",
    },
    pre: {
      margin: 0,
    },
  };


export default DisplayCode