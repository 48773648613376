// load server url
import endpoints from './endpoints.json';




// get course data java only
export const fetchData = async () => {
    try {
        const response = await fetch(`${endpoints.server1}/getcourses/learn-java`, {
            method: 'GET'
        
          });
          const data = await response.json();
       
          return data
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
};
// get course data java only - topics
export const Java_topics = async (lession,topic) => {
  try {
      const response = await fetch(`${endpoints.server1}/getcourses/learn-java/lessons-topics`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers as needed
          },
          body: JSON.stringify({ lession: lession, topic: topic }),

        });
        const data = await response.json();
     
        return data
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
// topic explanation
export const getExplanation = async(word="",line="",topic,version)=>{
  try {
    const response = await fetch(`${endpoints.server1}/coursetopic/explain`, {
      // const response = await fetch(`http://127.0.0.1:5000/coursetopic/explain`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({ version: version, word:word,line:line,topic:topic}),

      });
      const data = await response.json();
   
      return data
} catch (error) {
  console.error('Error fetching data:', error);
  throw error;
}
}
// for loading exaple for the topics
export const getExample = async(text,topic)=>{
  try {
    const response = await fetch(`${endpoints.server1}/coursetopic/explain/example`, {
      // const response = await fetch(`http://127.0.0.1:5000/coursetopic/explain`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({ version: "example",text:text,topic:topic}),

      });
      const data = await response.json();
   
      return data
} catch (error) {
  console.error('Error fetching data:', error);
  throw error;
}



}