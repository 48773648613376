import React, { useState, useEffect, useContext } from "react";
import logo from "../Assets/image/practcode.com.png";
import "../Assets/style/navbar.css";
import { NavLink, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { AuthenticatContext } from "../context/AuthenticatContext";

export default function Navbar(props) {
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const { authentication_data, update_authentication } =
    useContext(AuthenticatContext);

  const togglesidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };
  const location = useLocation();

  // useEffect(()=>{
  //   console.log(location);
  // },[location]);
  // logout
  const logout = () => {
    // Delete the cookie by calling the remove method
    Cookies.remove("authToken", { path: "/" }); // Provide the necessary options like path
    update_authentication({ authtoken: "", isLoggedIn: false });
  };
  return (
    <>
      <nav className="PractcodeNav practcode-theamer">
        <button className="PractcodeNavsidebartoggleBtn">
          <label className="hamburger">
            <input
              type="checkbox"
              id="navmainsidebarbtn"
              onClick={Object.keys(props.sidebar).length>0? togglesidebar:()=>{}}
            />
            <svg viewBox="0 0 32 32">
              <path
                className="line line-top-bottom"
                d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
              ></path>
              <path className="line" d="M7 16 27 16"></path>
            </svg>
          </label>
        </button>
        <div className="PractcodeNav_heading_logo">
          
        </div>
        <div className="navtaboptions practcode-theamer">
          <button>
            <NavLink
              className={`home  ${location === "/" ? "active" : ""}`}
              to="/"
            >
              Home
            </NavLink>
          </button>
          <button>
            <NavLink
              className={`course-details ${
                location === "/courses" ? "active" : ""
              } `}
              to="/"
            >
              Courses
            </NavLink>
          </button>

          <button>
            <a href="/">Contests</a>
          </button>
          <button>
            <NavLink
              className={`dashboard  ${location === "/" ? "active" : ""}`}
              to=""
            >
              Dashboard
            </NavLink>
            </button>
        </div>
        <div className="PractcodeNav_options">
          <div className="PractcodeNav_notify">
            <i className="fa-solid fa-bell"></i>
            <p id="PractcodeNav_notify"></p>
          </div>
        </div>
      </nav>

      <div className="PractcodeNavSideBody">
        <div
          id="PractcodeSideNavid"
          className={`PractcodeSide_nav ${
            isSidebarVisible ? "activeSidenav" : ""
          }`}
        >
          <div
            id="PractcodeSidebar_userOptionsid"
            className={`PractcodeSidebar_userOptions ${
              isSidebarVisible ? "activeuser" : ""
            }`}
          >
          
            <ul className="userOptions_section_sidenav">
              {Object.keys(props.sidebar).map((key, index) => (
                <li key={index} className= {`side-navigation-options ${isSidebarVisible ? "activeSidenav" : ""}`}>
                  <NavLink to={props.sidebar[key]}>
                    <i className="fa-solid fa-circle-dot"></i>
                    {key}
                  </NavLink>
                </li>
              ))}
              
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
// 2024-01-15T10:25:21.809+00:00
